export default class ProjectAppTableItem {
    projectAppId = 0;
    projectAppStatus = 0;
    projectAppSortId = 0;
    projectId = 0;
    projectAppType = 0;
    projectAppUrl = "";
    projectAppPort = 0;
    projectAppMid = 0;
    projectAppUser = "";
    projectAppPass = "";
    projectAppColor = 0;
    projectAppRemark = "";
    createTime = "";
    createName = "";
    modifyTime = "";
    modifyName = "";

    orgCode = "";
    orgName = "";
    projectName = "";
    projectAppTypeName = "";
    projectAppColorName = "";
}
