import baseApi from "@/api/baseApi";
import requests from "@/utils/axios";

export default {
    ...baseApi,
    // 模块URL
    modulesUrl: "/project/project/app",
    // 层级选择器
    levelSelect(query: any) {
        return requests.post(this.modulesUrl + "/levelSelect", query);
    },
};
