import { Options } from "vue-class-component";
import BaseTableInterface from "@/mixins/BaseTableInterface";
import BaseTableMixins from "@/mixins/BaseTable";
import SelectLevel from "@/modules/select-level/select-level.vue";
import DraggableListview from "@/modules/draggable-listview/draggable-listview.vue";
import ProjectAppTableItem from "@/entity/project/ProjectAppTableItem";
import API from "@/api/project/project-app";
import OrgAPI from "@/api/system/org";
import ProjectAPI from "@/api/project/project-list";

@Options({
    name: "app-project-project-app",
    components: {
        "app-select-level": SelectLevel,
        "app-draggable-listview": DraggableListview,
    },
})
export default class ProjectApp extends BaseTableMixins<ProjectAppTableItem> implements Partial<BaseTableInterface<ProjectAppTableItem>> {
    public created(): void {
        this.setInterface(this);
        this.tableRequestItem.lockItem = this.getLockItem();
        this.editTableItem = new ProjectAppTableItem();
        this.editModalItem = new ProjectAppTableItem();
    }

    //锁定列数据实体
    public getLockItem(): any {
        return {
            orgCode: {
                type: "router",
                name: "p.org_code",
                title: "所属组织",
                parent: this.authUser.orgCode,
                value: null,
                openFn: this.orgCodeLevelModalOpen,
            },
            projectId: {
                type: "eq",
                name: "p.project_id",
                title: "所属设备",
                parent: this.authUser.orgCode,
                value: null,
                openFn: this.projectIdLevelModalOpen,
            },
        };
    }

    public activated(): void {
        this.getList(1);
    }

    //后端API
    public getApi(): any {
        return API;
    }

    //OrgAPI
    get getOrgApi(): any {
        return OrgAPI;
    }

    //ProjectAPI
    get getProjectApi(): any {
        return ProjectAPI;
    }

    // //列表回调
    // public listCallback(items: Array<ProjectAppTableItem>): void {
    //     return;
    // }

    //添加弹窗配置
    public addModalSetting(): void {
        this.editTableItem = new ProjectAppTableItem();
        this.editModalItem = new ProjectAppTableItem();
        this.editModalItem.projectAppUrl = "xxx.yto.sotecauto.com";
        this.editModalItem.projectAppStatus = 1;
        this.editModalItem.projectAppType = 1;
        this.editModalItem.projectAppPort = 7510;
        this.editModalItem.projectAppMid = 1;
        this.editModalItem.projectAppUser = "yto";
        this.editModalItem.projectAppPass = "yto";
        this.editModalTitle = "添加服务";
        this.editModalSubmitButtonName = "添加";
    }
    //添加提交检测
    public addSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.projectAppStatus)) return `服务状态不能为空！`;
        if (this.$tools.isEmpty(this.editModalItem.projectId)) return `所属设备不能为空！`;
        if (this.$tools.isEmpty(this.editModalItem.projectAppType)) return `协议类型不能为空！`;
        if (this.$tools.isEmpty(this.editModalItem.projectAppUrl)) return `服务地址不能为空！`;
        if (this.$tools.isEmpty(this.editModalItem.projectAppPort)) return `服务端口不能为空！`;
        if (this.$tools.isEmpty(this.editModalItem.projectAppMid)) return `分拣机ID不能为空！`;
        if (this.$tools.isEmpty(this.editModalItem.projectAppUser)) return `登入用户不能为空！`;
        if (this.$tools.isEmpty(this.editModalItem.projectAppColor)) return `主题颜色不能为空！`;
        if (this.editModalSubmitButtonName == "添加") {
            if (this.$tools.isEmpty(this.editModalItem.projectAppPass)) return `用户密码不能为空！`;
        }
        return null;
    }
    // //添加回调
    // public addModalCallback(res: any): void {
    //     return;
    // }

    //修改弹窗配置
    public modModalSetting(): void {
        this.editModalTitle = "修改服务";
        this.editModalItem.projectAppPass = "";
        this.editModalSubmitButtonName = "修改";
    }
    //修改提交检测
    public modSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.projectAppId)) return `APPID不能为空！`;
        return this.addSubmitChecking();
    }
    // //修改回调
    // public modModalCallback(res: any): void {
    //     return;
    // }

    //删除提示框配置
    public delAlertConfig(): any {
        return {
            type: "okAndNo",
            title: `删除服务`,
            message: `所属设备: ${this.editModalItem.projectName}<br>请确认删除？`,
        };
    }
    // //删除提示框配置
    // public delAlertSetting(): void {
    //     return;
    // }
    //删除提示框提交检测
    public delSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.projectAppId)) return `APPID不能为空！`;
        return null;
    }
    // //删除回调
    // public delAlertCallback(res: any): void {
    //     return;
    // }

    //状态提示框配置
    public statusAlertConfig(): any {
        return {
            title: `服务状态`,
            message: `所属设备: ${this.editModalItem.projectName}<br>请确认修改状态？`,
            submitButtonName: this.editModalItem.projectAppStatus === 1 ? "启用" : "禁用",
        };
    }
    //状态切换请求配置
    public toggleStatusSetting(): void {
        this.editModalItem.projectAppStatus = this.editModalItem.projectAppStatus === 1 ? 2 : 1;
    }
    //状态切换提交检测
    public toggleStatusSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.projectAppId)) return `APPID不能为空！`;
        return null;
    }
    // //状态切换回调
    // public toggleStatusCallback(): void {
    //     return;
    // }

    //排序弹窗配置
    public sortModalSetting(): void {
        this.editSortBaseTitle = `服务排序`;
    }

    //orgCode搜索字段
    private orgCodeLevelSearchField: any = [
        { field: "org_code", name: "代码" },
        { field: "org_name", name: "名称" },
        { field: "org_remark", name: "说明" },
    ];
    //orgCode层级选择器打开
    public orgCodeLevelModalOpen() {
        (this.$refs.orgCodeLevel as any).openModal();
    }
    //orgCode层级选择器提交
    public async orgCodeLevelFunction(item: any): Promise<void> {
        const curItem = this.$tools.isEmpty(item.value) ? this.getLockItem().orgCode : item;
        this.tableRequestItem.lockItem.orgCode.value = curItem.value;
        this.tableRequestItem.lockItem.orgCode.title = curItem.title;
        this.tableRequestItem.lockItem.orgCode.parent = curItem.parent;
        await this.getList(1);
        return Promise.resolve();
    }

    //projectId搜索字段
    private projectIdLevelSearchField: any = [
        { field: "project_name", name: "名称" },
        { field: "project_remark", name: "说明" },
    ];
    //projectId层级选择器打开
    public projectIdLevelModalOpen() {
        (this.$refs.projectIdLevel as any).openModal();
    }
    //projectId层级选择器提交
    public async projectIdLevelFunction(item: any): Promise<void> {
        const curItem = this.$tools.isEmpty(item.value) ? this.getLockItem().projectId : item;
        this.tableRequestItem.lockItem.projectId.value = curItem.value;
        this.tableRequestItem.lockItem.projectId.title = curItem.title;
        this.tableRequestItem.lockItem.projectId.parent = curItem.parent;

        await this.getList(1);
        return Promise.resolve();
    }

    get projectIdSelectApi() {
        return {
            api: (query: any) => ProjectAPI.idSelect(query),
            parent: "org_code",
            status: "project_status",
            name: "project_name",
            id: "project_id",
            cid: this.editModalItem.projectId,
        };
    }
}
